import React from 'react';
import styled from 'styled-components';

import { GoBackRenderer } from '../../components/header/go-back-renderer';
import { i18n } from '../../translations';
import { Layout } from '../../components';
import Background from '../../assets/images/service-maintenance.jpg';
import { MEDIA_QUERY, COLORS } from '../../commonStyles';

type Props = {
  language: string;
};

const Container = styled.div({
  backgroundImage: `url(${Background})`,
  width: '100%',
  height: 'calc(100vh - 78px)',
  [MEDIA_QUERY.TABLET_AND_DESKTOP]: {
    height: 'calc(100vh - 126px)',
  },

  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  color: COLORS.DARK_GREY,
});

const Title = styled.div({
  fontSize: '36px',
  fontWeight: 'bold',
  lineHeight: 1.22,
  [MEDIA_QUERY.DESKTOP]: {
    fontSize: '66px',
  },
});

const TextContainer = styled.div({
  margin: '24px 16px 0 16px',
  [MEDIA_QUERY.TABLET]: {
    margin: '60px 42px 0 42px',
  },
  [MEDIA_QUERY.DESKTOP]: {
    margin: '60px 42px',
  },
});

const Subtitle = styled.div({
  fontSize: '16px',
  lineHeight: 1.5,
  marginTop: '24px',
  width: '80%',
  [MEDIA_QUERY.TABLET]: {
    width: '60%',
  },
  [MEDIA_QUERY.DESKTOP]: {
    width: '40%',
    marginTop: '42px',
    fontSize: '18px',
  },
});

const Line = styled.p({ margin: 0 });

const ServiceMaintenance: React.FC<Props> = ({ language }) => {
  const __ = i18n(language);

  return (
    <Layout
      language={language}
      title={__('serviceMaintenance.title')}
      headerLeftRenderer={GoBackRenderer}
      hideFooter
      hideFooterBox
    >
      <Container>
        <TextContainer>
          <Title>{__('serviceMaintenance.title')}</Title>
          <Subtitle>
            {__('serviceMaintenance.subtitle')
              .split('\n')
              .map((line: string, i: number) => (
                <Line key={`${line}-${i}`}>{`${line}`}</Line>
              ))}
          </Subtitle>
        </TextContainer>
      </Container>
    </Layout>
  );
};

export default ServiceMaintenance;
